import { picoapp } from 'picoapp';
import { size } from '@selfaware/martha';
import flickityAsNavFor from 'flickity-as-nav-for';

import lazy from '@/components/lazy';
import drawer from '@/components/drawer';
import toggle from '@/components/toggle';
import menu from '@/components/menu';
import slideshow from '@/components/slideshow';
import flickity from '@/components/flickity';
import zoom from './components/zoom';
import idle from '@/components/idle';
import copyright from '@/components/copyright';
import lineTrail from '@/components/lineTrail';
import footer from '@/components/footer';
import signup from '@/components/signup';
import preferences from '@/components/preferences';
import locations from '@/components/locations';
import checkoutLocationSelector from '@/components/checkoutLocationSelector';
import checkoutLocationSelectorV2 from '@/components/checkoutLocationSelectorV2';
import scrollRotate from '@/components/scrollRotate';
import video from '@/components/video';
import videoPlayer from '@/components/videoPlayer';
import fadeProgress from '@/components/fadeProgress';
import listicle from '@/components/listicle';
import marquee from '@/components/marquee';
import appointmentConfirmation from '@/components/appointmentConfirmation';
import bundleConfirmation from '@/components/bundleConfirmation';
import lottie from '@/components/lottie';
import videoCarousel from '@/components/videoCarousel';
import designGeneratorQuiz from '@/components/designGeneratorQuiz';
import heapTracker from '@/components/heapTracker';
import designNowPage from '@/components/designNowPage';
import imageCollection from '@/components/imageCollection';
import reviewsCarousel from '@/components/reviewsCarousel';
import purchaser from '@/components/purchaser';
import flashSheet from '@/components/flashSheet';
import lookbook from '@/components/lookbook';
import stripeCheckout from './components/stripeCheckout';

const components = {
  lazy,
  drawer,
  toggle,
  menu,
  slideshow,
  flickity,
  idle,
  zoom,
  copyright,
  lineTrail,
  footer,
  signup,
  preferences,
  locations,
  checkoutLocationSelector,
  checkoutLocationSelectorV2,
  scrollRotate,
  fadeProgress,
  video,
  videoPlayer,
  listicle,
  marquee,
  appointmentConfirmation,
  bundleConfirmation,
  lottie,
  videoCarousel,
  designGeneratorQuiz,
  heapTracker,
  designNowPage,
  imageCollection,
  reviewsCarousel,
  purchaser,
  flashSheet,
  lookbook,
  stripeCheckout,
};

const state = {
  ...size(),
};

export default picoapp(components, state);
